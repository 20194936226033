import React from "react";
import "bulma/css/bulma.css";

import Media from "react-media";

class Section extends React.Component {
  render() {
    return (
      <Media query="(min-width: 990px)">
        {matches =>
          matches ? (
            <div className="stackedStuffColumn">
              <img
                className="stackedIColumn"
                src={this.props.backImage}
                alt=""
              />
              <div className="textStuff">
                <p className="stackedFirstP">{this.props.title}</p>
                <p className="stackedSecondPColumn">{this.props.description}</p>
              </div>
            </div>
          ) : (
            <div className="stackedStuff">
              <img className="stackedI" src={this.props.backImage} alt="" />
              <div className="textStuff">
                <p className="stackedFirstP">{this.props.title}</p>
                <p className="stackedSecondPColumn">{this.props.description}</p>
              </div>
            </div>
          )
        }
      </Media>
    );
  }
}

export default Section;
