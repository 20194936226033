import React from "react";
import "bulma/css/bulma.css";
import Layout from "../components/layout";
import Section from "../components/sections";
import backImage from "../../static/designer.jpg";
import mainBack from "../../static/heartSec.png";
export default () => (
  <Layout backgroundimage={mainBack}>
    <Section
      backImage={backImage}
      title="The Designer"
      description={[
        <p className="aboutHolder">
          <span>
            Born and raised in Tripoli - Lebanon, her projects always reflected
            somehow who she is and where she came from.
          </span>
          <span>
            Studied at Lycee Aslfonse De La Martine and graduated in
            socio-economics, continued her higher education and graduated with a
            BA in interior architecture. That was just the beginning of her
            journey.
          </span>
          <span>
            With our world constantly changing, to satisfy the market trends and
            innovations in production and technology, to keep up with
            socio-enviromental and cultural differences, Joyce completed a
            master course in global design at ALBA (Academie Libanaise des beaux
            arts- Beirut) with high distinction. Her thesis dealt with “Tripoli:
            design and crisis”. In 2013, she entered the “Design for change”
            contest in Lille, France and took third place for a pitch design
            idea for a new commerce in Pile city / Roubaix.
          </span>
          <span>
            He career kicked off when she took part in the “Aghabani embroidery
            training project” as a design-trainer collaborating with l’artisan
            du Liban , UNHCR and Mada. The project aimed at training
            underprivileged women on design procedures, working with them on
            prototypes of items later sold at l’Artisan du Liban stores and thus
            securing these women jobs.
          </span>
          <span>
            Her love for designing accessories made her create her first brand
            “Lore by Joyce Rouhana” and continue on creating handmade products
            executed by Lebanese handicrafters. In 2017, she travelled to
            Florence and took a semester in Jewelry making. Once back in
            Lebanon, she developed her own line of high-end jewelry sold online
            and collaborated once again with l’Artisan du Liban to design
            exclusively for them the “Machrabya” collection inspired by the
            almost disappearing traditional art in Tripoli.
          </span>
          <span>
            Graduated from HRD as a certified diamonds grader, Joyce seeks to
            develop also fine high-end jewelry always inspired from her
            background environment, history, cities, different exposure and
            surroundings, emotions and people’s stories.
          </span>
        </p>
      ]}
    ></Section>
  </Layout>
);
